import { Tabs, TabsList, TabsTrigger } from '@radix-ui/react-tabs';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

type TabValue =
  | 'mailing'
  | 'dostawa'
  | 'godziny-pracy'
  | 'przyjmowanie-zamowien'
  | 'sup';

export const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<TabValue>('mailing');
  const [tabContent, setTabContent] = useState<{
    [key in TabValue]: JSX.Element;
  }>({
    mailing: (
      <>
        <h1 className="text-2xl font-semibold leading-loose px-10 pt-10">
          Ustawienia mailingu
        </h1>
        <Outlet />
      </>
    ),
    dostawa: (
      <>
        <h1 className="text-2xl font-semibold leading-loose px-10 pt-10">
          Ustawienia dostawy
        </h1>
        <Outlet />
      </>
    ),
    'godziny-pracy': (
      <>
        <h1 className="text-2xl font-semibold leading-loose px-10 pt-10">
          Ustawienia godzin pracy
        </h1>
        <Outlet />
      </>
    ),
    'przyjmowanie-zamowien': (
      <>
        <h1 className="text-2xl font-semibold leading-loose px-10 pt-10">
          Przyjmowanie zamowień
        </h1>
        <Outlet />
      </>
    ),
    sup: (
      <>
        <h1 className="text-2xl font-semibold leading-loose px-10 pt-10">
          Ceny SUP
        </h1>
        <Outlet />
      </>
    ),
  });

  const handleTabClick = (value: TabValue) => {
    navigate(`/ustawienia/${value}`);
    setActiveTab(value);
  };

  const getLabel = (tab: TabValue) => {
    if (tab === 'godziny-pracy') return 'Godziny pracy';
    if (tab === 'przyjmowanie-zamowien') return 'Przyjmowanie zamówień';
    return tab.charAt(0).toUpperCase() + tab.slice(1);
  };

  useEffect(() => {
    const pathname = location.pathname;
    const currentTab = pathname.split('/').pop() as TabValue;
    setActiveTab(currentTab);
  }, [location.pathname]);

  const tabTriggers = [
    'mailing',
    'dostawa',
    'godziny-pracy',
    'przyjmowanie-zamowien',
    'sup',
  ].map((tab) => (
    <TabsTrigger
      key={tab}
      value={tab}
      onClick={() => handleTabClick(tab as TabValue)}
      className={
        activeTab === tab
          ? 'bg-slate-200 px-5 py-2 rounded-md'
          : 'px-5 py-2 rounded-md'
      }
    >
      {getLabel(tab as TabValue)}
    </TabsTrigger>
  ));

  return (
    <div className="flex flex-col">
      <Tabs defaultValue={activeTab} className="w-full">
        <TabsList className="px-10 pt-10 flex">{tabTriggers}</TabsList>
        {tabContent[activeTab]}
      </Tabs>
    </div>
  );
};
