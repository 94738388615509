import { useGetIngredientCategoriesQuery } from '@/api/ingredient-category';
import {
  useCreateIngredientMutation,
  useToggleIngredientAvailabilityMutation,
  useUpdateIngredientMutation,
} from '@/api/ingredients';
import { PizzaSizePrice } from '@/api/pizza';
import { useGetSizesQuery } from '@/api/sizes';
import { Ingredient } from '@/types/ingredient';
import { yupResolver } from '@hookform/resolvers/yup';
import { File, Info, Plus } from 'lucide-react';
import { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button } from '../ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { Switch } from '../ui/switch';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';
import { Size } from '@/types/size';

const Schema = yup.object({
  name: yup.string().max(60, {
    message: 'Nazwa może zawierać maksymalnie 60 znaków',
  }),
  img: yup.string().optional(),
  available: yup.boolean(),
});

type Props = {
  light?: boolean;
  type?: 'update';
  ingredient?: Ingredient;
};

export const AddEditIngredient = ({
  light,
  type,
  ingredient,
}: Props): ReactElement => {
  const currentIngredientSizePrices =
    ingredient?.ingredientSizePrice.map(({ size, price }) => ({
      price,
      sizeId: size.id,
    })) || [];

  const [open, setOpen] = useState(false);
  const [ingredientSizePrices, setIngredientSizePrices] = useState<
    PizzaSizePrice[]
  >(currentIngredientSizePrices);
  const [ingredientCategory, setIngredientCategory] = useState<string>(
    ingredient?.ingredientCategory.id || '',
  );
  const [createIngredient] = useCreateIngredientMutation();
  const [updateIngredient] = useUpdateIngredientMutation();

  const { data: ingredientCategories } = useGetIngredientCategoriesQuery();
  const { data } = useGetSizesQuery();

  const isUpdate = type === 'update';

  const form = useForm<yup.InferType<typeof Schema>>({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: ingredient ? ingredient.name : '',
      img: ingredient ? ingredient.img : '',
      available: !!ingredient?.available,
    },
  });

  const onSubmit = async (values: yup.InferType<typeof Schema>) => {
    try {
      const isValid = await form.trigger();
      if (!isValid) return;
      if (type === 'update') {
        await updateIngredient({
          ingredientId: ingredient?.id!,
          name: values.name || '',
          available: values.available!,
          sizePrices: ingredientSizePrices,
          ingredientCategoryId: ingredientCategory,
        });
      } else {
        await createIngredient({
          name: values.name || '',
          available: values.available!,
          sizePrices: ingredientSizePrices,
          ingredientCategoryId: ingredientCategory,
        });
      }

      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {isUpdate ? (
          <Button variant="ghost" className="px-2 w-full justify-start">
            <File className="w-4 h-4 mr-1" />
            Edytuj
          </Button>
        ) : (
          <Button
            className={
              light
                ? 'bg-slate-100 hover:bg-slate-200 border border-slate-300 text-slate-900'
                : ''
            }
          >
            <Plus className={`h-5 w-5 mr-2 text-slate-500`} />
            <p className="text-sm font-medium leading-normal">Dodaj składnik</p>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="w-132 p-8">
        <DialogHeader>
          <DialogTitle className="text-2xl font-semibold leading-loose mb-2">
            {type === 'update' ? 'Edytuj składnik' : 'Dodaj składnik'}
          </DialogTitle>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-6 w-full"
            >
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nazwa</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex items-center gap-x-4">
                <Select
                  value={ingredientCategory}
                  onValueChange={(ingredientCategoryId) => {
                    setIngredientCategory(ingredientCategoryId);
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Wybierz kategorię składnika" />
                  </SelectTrigger>
                  <SelectContent>
                    {ingredientCategories &&
                      ingredientCategories.ingredientCategories.map(
                        ({ id, name }) => (
                          <SelectItem key={id} value={id}>
                            {name}
                          </SelectItem>
                        ),
                      )}
                  </SelectContent>
                </Select>
                <TooltipProvider>
                  <Tooltip>
                    <div className="flex flex-col items-center gap-1">
                      <TooltipTrigger asChild className="px-2.5">
                        <div className="flex items-center">
                          <p className="text-sm">Dostępność</p>
                          <Info className="w-3.5 h-3.5 ml-1 text-slate-400" />
                        </div>
                      </TooltipTrigger>
                      <Switch
                        checked={form.watch('available')}
                        onCheckedChange={(value) =>
                          form.setValue('available', value)
                        }
                      />
                    </div>
                    <TooltipContent>
                      <p className="text-xs leading-tight">
                        Wyłączenie tej opcji uniemożliwi użytkownikom zakup
                        pizzy
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
              <div>
                <div className="flex justify-between">
                  <p className="text-sm leading-tight">Rozmiar</p>
                  <p className="w-40 text-sm leading-tight">Cena</p>
                </div>
                {data?.pizzaSizes.map(({ name, id }) => {
                  const setSizePrice = (sizeId: string, price: number) => {
                    setIngredientSizePrices((prev) => {
                      if (
                        !prev.find((pizzaSize) => pizzaSize.sizeId === sizeId)
                      )
                        return [...prev, { price, sizeId }];

                      return prev.map((pizzaSize) => {
                        if (pizzaSize?.sizeId === sizeId)
                          return {
                            ...pizzaSize,
                            price,
                          };

                        return pizzaSize;
                      });
                    });
                  };

                  const getIngredientPriceBySizeId = (sizeId: Size['id']) => {
                    const price = ingredient?.ingredientSizePrice.find(
                      ({ size }) => size.id === sizeId,
                    );

                    return price?.price;
                  };
                  return (
                    <div key={id}>
                      <FormItem className="flex items-center justify-between">
                        <FormLabel className="text-sm">{name}</FormLabel>
                        <FormControl className="w-40">
                          <Input
                            placeholder="Cena"
                            defaultValue={getIngredientPriceBySizeId(id)}
                            onChange={(e) =>
                              setSizePrice(id, Number(e.target.value))
                            }
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    </div>
                  );
                })}
              </div>
              <DialogFooter className="sm:justify-end">
                <Button type="submit" className="bg-slate-900 text-white mt-4">
                  Zapisz składnik
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
