import { createBrowserRouter } from 'react-router-dom';
import { App } from './App';
import { IngredientCategories } from './components/ingredientCategories/IngredientCategories';
import { Ingredients } from './components/ingredients/Ingredients';
import { Login } from './components/login/Login';
import { OrdersView } from './components/ordersView/OrdersView';
import { Pizza } from './components/pizza/Pizza';
import { PizzaView } from './components/pizzaView/PizzaView';
import { ProductCategory } from './components/productCategory/ProductCategory';
import { ProductView } from './components/productView/ProductView';
import { Products } from './components/products/Products';
import { Delivery } from './components/settings/Delivery';
import { Mailing } from './components/settings/Mailing';
import { Settings } from './components/settings/Settings';
import { WorkingHours } from './components/settings/WorkingHours';
import { Sizes } from './components/sizes/Sizes';
import { IsPizzeriaOpened } from './components/settings/IsPizzeriaOpen';
import { SupPrices } from './components/settings/SupPrices';

export const router = createBrowserRouter([
  {
    path: '/*',
    element: <App />,
    children: [
      {
        path: 'zamowienia',
        element: <OrdersView />,
      },
      {
        path: 'pizza',
        element: <PizzaView />,
        children: [
          {
            path: 'pizza',
            element: <Pizza />,
          },
          {
            path: 'skladniki',
            element: <Ingredients />,
          },
          {
            path: 'rozmiary',
            element: <Sizes />,
          },
          {
            path: 'kategorie-składników',
            element: <IngredientCategories />,
          },
        ],
      },
      {
        path: 'produkty',
        element: <ProductView />,
        children: [
          {
            path: 'produkty',
            element: <Products />,
          },
          {
            path: 'kategorie-produktów',
            element: <ProductCategory />,
          },
        ],
      },
      {
        path: 'ustawienia',
        element: <Settings />,
        children: [
          {
            path: 'mailing',
            element: <Mailing />,
          },
          {
            path: 'dostawa',
            element: <Delivery />,
          },
          {
            path: 'godziny-pracy',
            element: <WorkingHours />,
          },
          {
            path: 'przyjmowanie-zamowien',
            element: <IsPizzeriaOpened />,
          },
          {
            path: 'sup',
            element: <SupPrices />,
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
]);
