import { Setting, useUpdateSettingMutation } from '@/api/settings';
import { Switch } from '../ui/switch';
import { ConfirmationModal } from '../ConfirmationModal';
import { Button } from '../ui/button';
import { useState } from 'react';

type Props = {
  item: Setting;
};

export const SettingSwitch = ({ item }: Props) => {
  const [updateSetting] = useUpdateSettingMutation();
  const [checked, setChecked] = useState<boolean>(item.value === 'true');

  return (
    <div className="w-full max-w-100 px-10 pt-10 flex items-center gap-x-4">
      <p>
        Czy pizzeria przyjmuje <br /> zamówienia przez stronę?
      </p>
      <Switch
        checked={checked}
        onCheckedChange={(value) => setChecked(value)}
      />
      <ConfirmationModal
        title="Czy jesteś pewny?"
        description="Zmiana ustawienia spowoduje zmiany w systemie."
        onConfirm={() =>
          updateSetting({ settingId: item.id, value: checked.toString() })
        }
      >
        <Button className="bg-slate-900 text-white mt-4">Zapisz</Button>
      </ConfirmationModal>
    </div>
  );
};
