import {
  useDeleteProductMutation,
  useToggleProductAvailabilityMutation,
} from '@/api/product';
import { Product } from '@/types/product';
import { trimId } from '@/utils/trimId';
import { ColumnDef } from '@tanstack/react-table';
import clsx from 'clsx';
import { CircleDot, Info, MoreHorizontal, Trash } from 'lucide-react';
import { useState } from 'react';
import { ConfirmationModal } from '../ConfirmationModal';
import { ShortenString } from '../ShortenStringClipboard';
import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { Switch } from '../ui/switch';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';
import { AddEditProducts } from './AddEditProducts';

export const columns: ColumnDef<Product>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    cell: ({ row }) => {
      return (
        <ShortenString
          content={row.original.id}
          argName="ID"
          trimCallback={trimId}
        />
      );
    },
  },
  {
    accessorKey: 'name',
    header: 'Nazwa',
  },
  {
    accessorKey: 'description',
    header: 'Opis',
  },
  {
    accessorKey: 'available',
    header: 'Dostępność',
    cell: ({ row }) => {
      return (
        <p
          className={clsx(
            row.original.available
              ? 'border-emerald-600 text-emerald-600'
              : 'border-red-400 text-red-500',
            'inline-flex items-center text-sm gap-x-1 px-2 py-1 rounded-md border border-1',
          )}
        >
          <CircleDot className="w-2.5 h-2.5" />{' '}
          {row.original.available ? 'Dostępny' : 'Niedostępny'}
        </p>
      );
    },
  },
  {
    accessorKey: 'price',
    header: 'Cena',
  },
  {
    accessorKey: 'quantity',
    header: 'Ilość',
  },
  {
    accessorKey: 'akcje',
    header: () => (
      <div
        style={{
          textAlign: 'right',
        }}
      >
        Akcje
      </div>
    ),
    enableHiding: false,
    cell: ({ row }) => {
      const [deleteProduct] = useDeleteProductMutation();
      const [isAvailable, setIsAvailable] = useState(
        !!row?.original?.available,
      );
      const [toggleProductAvailability] =
        useToggleProductAvailabilityMutation();
      const handleToggleAvailability = async () => {
        try {
          setIsAvailable((prev) => !prev);
          await toggleProductAvailability({ productId: row.original.id });
        } catch (error) {
          console.error(error);
        }
      };
      return (
        <div className="text-right">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="w-48 text-slate-900 text-sm font-medium leading-tight"
            >
              <DropdownMenuItem
                disabled
                className="text-slate-500 text-xs font-semibold leading-none"
              >
                Opcje
              </DropdownMenuItem>
              <DropdownMenuItem>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild className="w-full">
                      <div className="flex justify-between items-center w-full">
                        <div className="flex items-center">
                          <p>Dostępność</p>
                          <Info className="w-3.5 h-3.5 ml-1 text-slate-400" />
                        </div>
                        <Switch
                          key={row.original.id}
                          defaultChecked={isAvailable}
                          onCheckedChange={handleToggleAvailability}
                          className="w-11 h-6"
                        />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        Wyłączenie tej opcji uniemożliwi użytkownikom zakup
                        biletu
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </DropdownMenuItem>
              <AddEditProducts type="update" product={row.original} />
              <ConfirmationModal
                title="Delete"
                description="Delete message"
                onConfirm={() => deleteProduct({ productId: row.original.id! })}
              >
                <Button
                  variant="ghost"
                  className="px-2 w-full justify-start text-red-500 hover:text-red-500"
                >
                  <Trash className="w-4 h-4 mr-1" />
                  Usuń
                </Button>
              </ConfirmationModal>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      );
    },
  },
];
