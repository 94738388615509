import {
  useCreateProductMutation,
  useUpdateProductMutation,
} from '@/api/product';
import { useGetProductCategoriesQuery } from '@/api/product-category';
import { Product } from '@/types/product';
import { yupResolver } from '@hookform/resolvers/yup';
import { File, Info, Plus } from 'lucide-react';
import { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button } from '../ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { Switch } from '../ui/switch';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';

const Schema = yup.object({
  name: yup.string().max(60, 'Nazwa może zawierać maksymalnie 60 znaków'),
  description: yup.string(),
  available: yup.boolean(),
  price: yup.number(),
  quantity: yup.number(),
  productCategory: yup.string(),
});

type Props = {
  light?: boolean;
  type?: 'update';
  product?: Product;
};

export const AddEditProducts = ({
  light,
  type,
  product,
}: Props): ReactElement => {
  const [open, setOpen] = useState(false);
  const [isAvailable, setIsAvailable] = useState(!!product?.available);
  const { data: productCategories } = useGetProductCategoriesQuery();
  const [productCategory, setProductCategory] = useState<string>(
    product?.productCategory.id || '',
  );
  const [createProduct] = useCreateProductMutation();
  const [updateProduct] = useUpdateProductMutation();

  const isUpdate = type === 'update';

  const form = useForm<yup.InferType<typeof Schema>>({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: product?.name || '',
      description: product?.description || '',
      available: isAvailable,
      price: product?.price || 0,
      quantity: product?.quantity || 0,
    },
  });

  const onSubmit = async (values: yup.InferType<typeof Schema>) => {
    try {
      const isValid = await form.trigger();
      if (!isValid) {
        return;
      }

      if (type === 'update') {
        await updateProduct({
          ...values,
          productId: product?.id!,
          name: values.name || '',
          description: values.description,
          available: isAvailable,
          price: values.price || 0,
          quantity: values.quantity || 0,
          productCategoryId: productCategory,
        });
      } else {
        await createProduct({
          ...values,
          name: values.name || '',
          description: values.description,
          available: isAvailable,
          price: values.price || 0,
          quantity: values.quantity || 0,
          productCategoryId: productCategory,
        });
      }

      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {isUpdate ? (
          <Button variant="ghost" className="px-2 w-full justify-start">
            <File className="w-4 h-4 mr-1" />
            Edytuj
          </Button>
        ) : (
          <Button
            className={
              light
                ? 'bg-slate-100 hover:bg-slate-200 border border-slate-300 text-slate-900'
                : ''
            }
          >
            <Plus className={`h-5 w-5 mr-2 text-slate-500`} />
            <p className="text-sm font-medium leading-normal">Dodaj produkt</p>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="w-132 p-8">
        <DialogHeader>
          <DialogTitle className="text-2xl font-semibold leading-loose mb-2">
            {type === 'update' ? 'Edytuj produkt' : 'Dodaj produkt'}
          </DialogTitle>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-6 w-full"
            >
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nazwa</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Opis</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Select
                value={productCategory}
                onValueChange={(productCategoryId) => {
                  setProductCategory(productCategoryId);
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Wybierz kategorię produktu" />
                </SelectTrigger>
                <SelectContent>
                  {productCategories &&
                    productCategories.productCategories.map(({ id, name }) => (
                      <SelectItem key={id} value={id}>
                        {name}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
              <div className="flex justify-between gap-x-4 items-end">
                <FormField
                  control={form.control}
                  name="price"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Cena</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="quantity"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Ilość</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <TooltipProvider>
                  <Tooltip>
                    <div className="flex flex-col items-center gap-1">
                      <TooltipTrigger asChild className="px-2.5">
                        <div className="flex items-center">
                          <p className="text-sm">Dostępność</p>
                          <Info className="w-3.5 h-3.5 ml-1 text-slate-400" />
                        </div>
                      </TooltipTrigger>
                      <Switch
                        onCheckedChange={() => setIsAvailable(!isAvailable)}
                        checked={isAvailable}
                      />
                    </div>
                    <TooltipContent>
                      <p className="text-xs leading-tight">
                        Wyłączenie tej opcji uniemożliwi użytkownikom zakup
                        pizzy
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
              <DialogFooter className="sm:justify-end">
                <Button type="submit" className="bg-slate-900 text-white mt-4">
                  Zapisz produkt
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
