import { useChangeOrderStatusMutation } from '@/api/orders';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Order, OrderItem as OrderI, OrderStatus } from '@/types/order';
import clsx from 'clsx';
import { format } from 'date-fns';
import {
  ArrowLeftCircle,
  ArrowRightCircle,
  Banknote,
  Building2,
  CircleDot,
  Clock,
  PhoneCall,
  Truck,
  AtSign,
} from 'lucide-react';
import { OrderActions } from './OrderActions';

type Props = {
  order: Order;
};

export const OrderItem = ({ order }: Props) => {
  const statuses = ['new', 'realization', 'in_delivery', 'ready'];
  const arrowStyle =
    'size-8 text-slate-800 opacity-90 hover:opacity-100 hover:scale-105 hover:duration-300 rotate-90 2xl:rotate-0';
  const [changeOrderStatus] = useChangeOrderStatusMutation();
  const statusIndex = statuses.indexOf(order.status);
  const isFirstStatus = statusIndex === 0;
  const isLastStatus = statusIndex === statuses.length - 1;

  const handleStatusChange = async (change: number) => {
    const newIndex = statusIndex + change;
    if (newIndex >= 0 && newIndex < statuses.length) {
      const newStatus = statuses[newIndex] as OrderStatus;
      await changeOrderStatus({ orderId: order.id, status: newStatus });
    }
  };

  return (
    <div
      className={clsx(
        'flex flex-col p-5 rounded border justify-between 2xl:justify-start',
        {
          'border-red-500': order.status === 'new',
          'border-amber-500': order.status === 'realization',
          'border-blue-500': order.status === 'in_delivery',
          'border-green-500': order.status === 'ready',
        },
      )}
    >
      <div className="flex items-center justify-between gap-x-4 2xl:gap-x-0 mb-3">
        <p className="inline-flex whitespace-nowrap items-center text-sm gap-x-1 px-2 py-1 rounded-md border border-slate-800">
          <CircleDot className="w-2.5 h-2.5" />{' '}
          {order.deliveryType === 'COURIER_DELIVERY'
            ? 'Dostawa'
            : 'Odbiór osobisty'}
        </p>
        <div className="flex items-center gap-x-4">
          <p className="flex gap-x-1.5 items-center">
            <Clock className="size-3" />
            <span className="font-medium">
              {format(new Date(order.createdAt), 'dd/MM/yyyy ')}
            </span>
            <span className="font-semibold">
              {format(new Date(order.createdAt), 'HH:mm')}
            </span>
          </p>
          <OrderActions order={order} />
        </div>
      </div>
      <div className="flex flex-col gap-y-1">
        {order.deliveryType === 'COURIER_DELIVERY' && (
          <p className="flex gap-x-1.5 items-center">
            <Truck className="size-3" />
            {order.address.city} {order.address.postalCode},{' '}
            {order.address.street} {order.address.homeNumber}
            {order.address.flatNumber &&
              `/${order.address.flatNumber} ${!!order?.address?.floor ? 'piętro ' + order.address.floor : ''}`}
          </p>
        )}
        {order.businessName && (
          <p className="flex gap-x-1.5 items-center font-semibold">
            <Building2 className="size-3" />
            {order.businessName} <span className="font-normal">|</span>{' '}
            {order.taxId}
          </p>
        )}
        <p className="flex gap-x-1.5 items-center">
          <PhoneCall className="size-3" />
          {order.phoneNumber}
        </p>
        <p className="flex gap-x-1.5 items-center">
          <AtSign className="size-3" />
          {order.email}
        </p>
        <p className="flex gap-x-1.5 items-center mb-4 font-semibold">
          <Banknote className="size-3" />
          {Number(order.totalPrice + order.deliveryPrice)} zł
        </p>
      </div>
      <div className="flex flex-col gap-y-1 mt-4 mb-4">
        {order.orderItems.filter((item: OrderI) => item.type === 'PIZZA')
          .length > 0 && (
          <Accordion defaultValue="items-1" type="single" collapsible>
            <AccordionItem value="items-1">
              <AccordionTrigger>Pizza</AccordionTrigger>
              <AccordionContent className="flex flex-col gap-y-2">
                {order.orderItems
                  .filter((item: OrderI) => item.type === 'PIZZA')
                  .map(
                    (
                      { quantity, name, description, pizzaSize }: OrderI,
                      index: number,
                    ) => (
                      <p key={index}>
                        {quantity}x {name} - <b>{pizzaSize?.name}</b>
                        <br />
                        {(description.orderedIngredients as string[]).join(
                          ', ',
                        )}
                      </p>
                    ),
                  )}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )}
        {order.orderItems.filter((item: OrderI) => item.type === 'CUSTOM_PIZZA')
          .length > 0 && (
          <Accordion defaultValue="items-2" type="single" collapsible>
            <AccordionItem value="items-2">
              <AccordionTrigger>
                {order.orderItems.length > 1
                  ? 'Własne kompozycje'
                  : 'Własna kompozycja'}
              </AccordionTrigger>
              <AccordionContent>
                {order.orderItems
                  .filter((item: OrderI) => item.type === 'CUSTOM_PIZZA')
                  .map(
                    (
                      { quantity, name, description, pizzaSize }: OrderI,
                      index: number,
                    ) => (
                      <p key={index}>
                        {quantity}x {name} - <b>{pizzaSize?.name}</b>
                        <br />
                        {[
                          'Sos pomidorowy',
                          ...(description.orderedIngredients as string[]),
                        ].join(', ')}
                      </p>
                    ),
                  )}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )}
        {order.orderItems.filter(
          (item: OrderI) => item.type === 'HALF_BY_HALF_PIZZA',
        ).length > 0 && (
          <Accordion defaultValue="items-3" type="single" collapsible>
            <AccordionItem value="items-3">
              <AccordionTrigger>Pizza pół na pół</AccordionTrigger>
              <AccordionContent>
                {order.orderItems
                  .filter((item: OrderI) => item.type === 'HALF_BY_HALF_PIZZA')
                  .map(
                    ({ quantity, name, pizzaSize }: OrderI, index: number) => (
                      <p key={index}>
                        {quantity}x {name} - <b>{pizzaSize?.name}</b>
                        {}
                      </p>
                    ),
                  )}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )}
        {order.orderItems.filter((item: OrderI) => item.type === 'PRODUCT')
          .length > 0 && (
          <Accordion defaultValue="items-4" type="single" collapsible>
            <AccordionItem value="items-4">
              <AccordionTrigger>
                {order.orderItems.length > 1 ? 'Produkty' : 'Produkt'}
              </AccordionTrigger>
              <AccordionContent>
                {order.orderItems
                  .filter((item: OrderI) => item.type === 'PRODUCT')
                  .map(({ quantity, name }: OrderI, index: number) => (
                    <p key={index}>
                      {quantity}x {name}
                      {}
                    </p>
                  ))}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )}
      </div>
      <div
        className={clsx('flex mt-3', {
          'justify-end': isFirstStatus,
          'justify-start': isLastStatus,
          'justify-between': !isFirstStatus && !isLastStatus,
        })}
      >
        {!isFirstStatus && (
          <button onClick={() => handleStatusChange(-1)}>
            <ArrowLeftCircle className={arrowStyle} />
          </button>
        )}
        {!isLastStatus && (
          <button onClick={() => handleStatusChange(1)}>
            <ArrowRightCircle className={arrowStyle} />
          </button>
        )}
      </div>
    </div>
  );
};
