import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';
import { useLogout } from '@/hooks/useLogout';
import clsx from 'clsx';
import { ChevronDown, ChevronUp, LogOut, Settings, User } from 'lucide-react';
import { ReactElement, useState } from 'react';
import { Button } from '../ui/button';
import { AccountDetails } from './AccountDetails';
import { AvatarElement } from './AvatarElement';
import { Users } from './Users';

export const MenuUser = (): ReactElement => {
  const [content, setContent] = useState('account-details');
  const logout = useLogout();

  return (
    <Dialog>
      <DialogTrigger className="m-0">
        <div className="flex items-center gap-x-6 px-2 py-3">
          <AvatarElement />
          <div className="flex flex-col">
            <ChevronUp className="w-3 h-3" />
            <ChevronDown className="w-3 h-3" />
          </div>
        </div>
      </DialogTrigger>
      <DialogContent className="p-0 h-screen flex justify-end w-auto max-w-none right-0 left-auto -translate-x-0">
        {content === 'users' && <Users />}
        {content === 'account-details' && <AccountDetails />}
        <div className="flex flex-col justify-between rounded-tl-md bg-slate-50 rounded-bl-md border-l border-t border-b border-slate-300 pt-16 pb-4 min-w-80">
          <div>
            <div className="px-6 py-3 mb-4">
              <AvatarElement />
            </div>
            <Button
              variant="ghost"
              onClick={() => setContent('account-details')}
              className={clsx(
                'text-black text-sm font-medium justify-start leading-tight flex gap-x-2 items-center w-full px-6 py-3',
                content === 'account-details' &&
                  'bg-slate-200 hover:bg-slate-200 border-t border-b border-slate-300',
              )}
            >
              <User className="w-4 h-4" />
              <p>Dane konta</p>
            </Button>
            <Accordion type="single" collapsible>
              <AccordionItem value="item-1">
                <AccordionTrigger className="text-black !no-underline text-sm font-medium justify-start leading-tight flex gap-x-2 items-center w-full px-6 py-3">
                  <Settings className="w-4 h-4" />
                  <p>Ustawienia</p>
                </AccordionTrigger>
                <AccordionContent>
                  <Button
                    variant="ghost"
                    onClick={() => setContent('users')}
                    className={clsx(
                      'text-black text-sm font-medium px-12 py-3 w-full justify-start rounded-none',
                      content === 'users' &&
                        'bg-slate-200 hover:bg-slate-200 border-t border-b border-slate-300',
                    )}
                  >
                    <p>Użytkownicy</p>
                  </Button>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
          {/* Routing */}
          <Button
            variant="ghost"
            onClick={logout}
            className="text-black text-sm font-medium justify-start leading-tight flex gap-x-2 items-center w-full px-6 py-3"
          >
            <LogOut className="w-4 h-4" />
            <p>Wyloguj</p>
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
