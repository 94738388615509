import { useGetPizzasQuery } from '@/api/pizza';
import { DataTable } from '../DataTable';
import { NotFound } from '../NotFound';
import { AddEditPizza } from './AddEditPizza';
import { columns } from './columns';

export const Pizza = () => {
  const { data, isLoading } = useGetPizzasQuery();
  const pizzas = data?.pizzas?.length;

  return (
    <>
      <div className="flex justify-between items-center mt-12 mb-8 px-10">
        <h1 className="text-2xl font-semibold leading-loose">Lista pizzy</h1>
        {!!pizzas && <AddEditPizza light />}
      </div>

      {!!pizzas ? (
        <DataTable columns={columns} data={data.pizzas} />
      ) : isLoading ? null : (
        <NotFound
          description="Nie znaleziono żadnej pizzy"
          element={<AddEditPizza />}
        />
      )}
    </>
  );
};
